import { useUserStore } from "@/store/useUserStore";
import { storeToRefs } from "pinia";

export const useUserInfo = () => {
  const userStore = useUserStore();
  const { userInfo } = storeToRefs(userStore);

  return {
    userInfo
  }
}