export const Routes = Object.freeze({
  ROOT: '/',
  FULLSCREEN: '/f',
  GNB: '/g',
  PRIVATE: '/p',
  //
  LOGIN: '/f/login',
  AUTHORIZED: '/f/authorized',
  NOTFOUND: '/f/notfound',
  NETWORK_OFFLINE: '/f/network-offline',
  //
  DASHBOARD: '/g/dashboard',
  MONITORING: '/g/monitoring',
  MONITORING__GROUP: '/g/monitoring/group', // 집합자원
  MONITORING__RESOURCE: '/g/monitoring/resource', // 보유자원
  CONTROL: '/g/control',
  SIMULATION: '/g/simulation',

  BIDDING: '/g/bidding',
  BIDDING__MANAGE_PARTICIPATING_CAPACITY: '/g/bidding/participating-capacity',
  BIDDING__DAY_AHEAD: '/g/bidding/day-ahead',
  BIDDING__REALTIME: '/g/bidding/realtime',

  // 정산
  SETTLEMENT: '/g/settlement',
  SEND: '/g/settlement/send',
  SETTLEMENT__SETTING: '/g/settlement/setting',
  SETTLEMENT__PDF: '/settlement/pdf',

  RESOURCE: '/g/resource',

  SETTING: '/g/setting',
  P_METER_DATA: '/p/meter-data',
});

export const QSs = Object.freeze({
  [Routes.MONITORING__GROUP]: {
    GORUP_ID: 'group_id',
  },
  [Routes.MONITORING__RESOURCE]: {
    GROUP_ID: 'group_id',
    RESOURCE_ID: 'resource_id',
  },
  [Routes.CONTROL]: {
    GORUP_ID: 'group_id',
  },
});
