<template>
  <svg width="146" height="18" viewBox="0 0 146 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8551 0.685765H9.68797C9.63188 0.683979 9.57601 0.693625 9.5236 0.714147C9.4712 0.734669 9.42331 0.765648 9.38273 0.80529C9.34215 0.844932 9.30968 0.892445 9.28722 0.945049C9.26476 0.997654 9.25277 1.0543 9.25192 1.1117V7.28943H3.03916V1.1117C3.03832 1.0543 3.02632 0.997654 3.00387 0.945049C2.98141 0.892445 2.94895 0.844932 2.90837 0.80529C2.86778 0.765648 2.8199 0.734669 2.76749 0.714147C2.71509 0.693625 2.65921 0.683979 2.60313 0.685765H0.436046C0.37996 0.683979 0.324085 0.693625 0.271681 0.714147C0.219277 0.734669 0.17139 0.765648 0.130808 0.80529C0.0902253 0.844932 0.0577551 0.892445 0.0352983 0.945049C0.0128415 0.997654 0.000842785 1.0543 1.70558e-10 1.1117V16.8746C-3.22857e-06 16.9932 0.0458355 17.1071 0.127516 17.1912C0.209197 17.2754 0.3201 17.3232 0.436046 17.3241H2.60313C2.71907 17.3232 2.82998 17.2754 2.91166 17.1912C2.99334 17.1071 3.03917 16.9932 3.03916 16.8746V10.0194H9.25192V16.8646C9.25192 16.9832 9.29776 17.097 9.37944 17.1812C9.46112 17.2654 9.57202 17.3131 9.68797 17.314H11.8551C11.971 17.3131 12.0819 17.2654 12.1636 17.1812C12.2453 17.097 12.2911 16.9832 12.2911 16.8646V1.10164C12.2868 0.987464 12.2386 0.879652 12.1569 0.801738C12.0752 0.723825 11.9667 0.682139 11.8551 0.685765Z"
      fill="#353A3F" />
    <path
      d="M24.5526 2.7884C24.028 0.709038 22.451 0.507812 21.5134 0.507812C20.5757 0.507812 18.9955 0.709038 18.4709 2.7884L14.8843 16.8208C14.879 16.8582 14.8714 16.8951 14.8613 16.9315C14.8613 16.9834 14.8715 17.0348 14.8915 17.0826C14.9115 17.1303 14.9408 17.1734 14.9776 17.2092C15.0145 17.245 15.058 17.2727 15.1057 17.2907C15.1533 17.3087 15.204 17.3165 15.2547 17.3138H17.5726C17.6735 17.3059 17.7691 17.2642 17.8444 17.195C17.9197 17.1259 17.9705 17.0332 17.989 16.9315L18.9955 12.6822H24.028L25.0345 16.9315C25.0529 17.0327 25.1032 17.125 25.1779 17.1941C25.2525 17.2632 25.3473 17.3052 25.4476 17.3138H27.7688C27.8194 17.316 27.8699 17.3079 27.9173 17.2897C27.9648 17.2715 28.0081 17.2438 28.0449 17.2081C28.0816 17.1724 28.1109 17.1295 28.131 17.082C28.1512 17.0345 28.1618 16.9833 28.1622 16.9315C28.1522 16.8951 28.1445 16.8582 28.1392 16.8208L24.5526 2.7884ZM19.5856 10.1299L21.1626 3.48936C21.2282 3.22106 21.2937 3.13386 21.5134 3.13386C21.7331 3.13386 21.7953 3.22106 21.8609 3.48936L23.4379 10.1366L19.5856 10.1299Z"
      fill="#353A3F" />
    <path
      d="M39.7518 0.823643C38.3813 0.573631 36.9894 0.467992 35.5979 0.508383C32.7522 0.508383 30.5425 1.29317 30.5425 5.0964V12.9041C30.5425 16.7073 32.7522 17.4921 35.5979 17.4921C36.9893 17.5309 38.3809 17.4264 39.7518 17.1802C40.0796 17.1366 40.1911 17.0226 40.1911 16.7073V15.2786C40.1911 15.2196 40.1797 15.1611 40.1577 15.1066C40.1356 15.0521 40.1032 15.0025 40.0624 14.9608C40.0216 14.9191 39.9732 14.886 39.9199 14.8634C39.8666 14.8408 39.8095 14.8292 39.7518 14.8292H35.2898C34.021 14.8292 33.562 14.3831 33.562 12.9041V10.0198H39.4895C39.5472 10.0198 39.6043 10.0082 39.6576 9.9856C39.7109 9.96301 39.7594 9.92991 39.8002 9.88818C39.841 9.84645 39.8733 9.79692 39.8954 9.74239C39.9175 9.68787 39.9288 9.62942 39.9288 9.57041V7.96058C39.928 7.84166 39.8814 7.72785 39.7992 7.64376C39.717 7.55967 39.6057 7.51203 39.4895 7.51116H33.562V5.0964C33.562 3.61737 34.021 3.17132 35.2898 3.17132H39.7518C39.8683 3.17132 39.98 3.12397 40.0624 3.03969C40.1448 2.95541 40.1911 2.84111 40.1911 2.72192V1.29319C40.1911 0.977927 40.0796 0.867243 39.7518 0.823643Z"
      fill="#353A3F" />
    <path
      d="M53.7674 14.5838H47.0268C46.876 14.5838 46.8301 14.5369 46.8301 14.4497C46.841 14.3643 46.8777 14.2846 46.935 14.2216L53.7609 3.77113C53.9445 3.49999 54.0501 3.18161 54.0658 2.85216V1.55425C54.0658 0.970682 53.5412 0.702374 52.9084 0.702374H44.0762C44.021 0.700507 43.966 0.710247 43.9147 0.730991C43.8634 0.751736 43.8167 0.783043 43.7777 0.822974C43.7387 0.862904 43.7081 0.9106 43.6878 0.963122C43.6675 1.01564 43.658 1.07187 43.6598 1.12831V3.0098C43.6582 3.06512 43.6681 3.12015 43.6888 3.17129C43.7094 3.22243 43.7405 3.26853 43.7798 3.30654C43.8191 3.34454 43.8658 3.37361 43.9169 3.39184C43.968 3.41006 44.0223 3.41702 44.0762 3.41226H50.1578C50.3086 3.41226 50.3742 3.47933 50.3742 3.59001C50.3742 3.66257 50.3512 3.73319 50.3086 3.79125L43.3746 14.4195C43.1945 14.6658 43.0947 14.9638 43.0894 15.2714V16.5022C43.0894 17.1059 43.5713 17.3306 44.2073 17.3306H53.7674C53.8834 17.3297 53.9943 17.282 54.0759 17.1978C54.1576 17.1136 54.2035 16.9998 54.2035 16.8812V15.0031C54.2046 14.9454 54.1938 14.8881 54.172 14.8349C54.1501 14.7818 54.1175 14.7339 54.0764 14.6944C54.0354 14.6549 53.9866 14.6246 53.9334 14.6056C53.8802 14.5865 53.8236 14.5791 53.7674 14.5838Z"
      fill="#353A3F" />
    <path
      d="M62.508 0.507812C58.9016 0.507812 56.2788 2.56706 56.2788 7.49046V10.5089C56.2788 15.4323 58.9016 17.4915 62.508 17.4915C66.1143 17.4915 68.7371 15.4323 68.7371 10.5089V7.49046C68.7437 2.56706 66.1176 0.507812 62.508 0.507812ZM65.6815 10.5089C65.6815 13.4636 64.6521 14.785 62.508 14.785C60.3638 14.785 59.3377 13.4636 59.3377 10.5089V7.49046C59.3377 4.53575 60.3638 3.21435 62.508 3.21435C64.6521 3.21435 65.6815 4.53575 65.6815 7.49046V10.5089Z"
    fill="#353A3F" />
  <path
    d="M77.7135 0.507812C74.1072 0.507812 71.4844 2.56706 71.4844 7.49046V10.5089C71.4844 15.4323 74.1072 17.4915 77.7135 17.4915C81.3199 17.4915 83.9427 15.4323 83.9427 10.5089V7.49046C83.9459 2.56706 81.3231 0.507812 77.7135 0.507812ZM80.8838 10.5089C80.8838 13.4636 79.8577 14.785 77.7135 14.785C75.5694 14.785 74.5399 13.4636 74.5399 10.5089V7.49046C74.5399 4.53575 75.5694 3.21435 77.7135 3.21435C79.8577 3.21435 80.8838 4.53575 80.8838 7.49046V10.5089Z"
    fill="#353A3F" />
  <path
    d="M99.8728 0.685547H98.0795C97.9088 0.689978 97.7428 0.742808 97.5998 0.838151C97.4568 0.933495 97.3425 1.0676 97.2697 1.22551L93.9682 8.07064C93.8568 8.27187 93.8141 8.3859 93.7256 8.3859C93.6371 8.3859 93.5945 8.27187 93.4863 8.07064L90.1815 1.22551C90.109 1.06736 89.9948 0.933048 89.8518 0.83766C89.7087 0.742272 89.5425 0.689595 89.3718 0.685547H87.5883C87.3564 0.68643 87.1342 0.78105 86.9702 0.948792C86.8063 1.11653 86.7138 1.34379 86.7129 1.58101V16.9784C86.7124 17.0267 86.7222 17.0745 86.7414 17.1186C86.7607 17.1627 86.7891 17.202 86.8245 17.2338C86.86 17.2657 86.9018 17.2893 86.947 17.3031C86.9923 17.3169 87.0399 17.3205 87.0866 17.3138H89.2964C89.4931 17.3138 89.6439 17.0891 89.6439 16.8644V6.46753C89.6439 6.3099 89.6668 6.24283 89.7094 6.24283C89.7521 6.24283 89.7783 6.30989 89.8209 6.37697L91.9421 10.6531C92.0163 10.7737 92.1201 10.8722 92.2433 10.9387C92.3664 11.0053 92.5045 11.0374 92.6437 11.0321H94.8501C94.9893 11.0374 95.1274 11.0053 95.2506 10.9387C95.3737 10.8722 95.4776 10.7737 95.5517 10.6531L97.65 6.37697C97.6959 6.30989 97.7385 6.24283 97.7615 6.24283C97.7844 6.24283 97.827 6.3099 97.827 6.46753V16.8644C97.82 16.9706 97.8521 17.0756 97.9171 17.1588C97.982 17.242 98.075 17.2972 98.1778 17.3138H100.374C100.421 17.32 100.468 17.316 100.513 17.3019C100.558 17.2879 100.599 17.2642 100.634 17.2324C100.669 17.2006 100.697 17.1615 100.717 17.1177C100.736 17.0738 100.745 17.0263 100.745 16.9784V1.58101C100.744 1.34437 100.652 1.11762 100.489 0.949969C100.325 0.782322 100.104 0.687311 99.8728 0.685547Z"
    fill="#353A3F" />
  <path
    d="M110.826 1.40234H107.579L112.313 17.4922H116L120.755 1.40234H117.613L115.602 9.22984C115.12 11.0563 114.764 12.6 114.282 14.4264H114.178C113.675 12.6 113.361 11.0563 112.879 9.22984L110.826 1.40234Z"
    fill="#526FEC" />
  <path
    d="M126.791 1.40234H121.701V17.4922H124.801V11.7738H126.875C130.206 11.7738 132.866 10.1213 132.866 6.44673C132.866 2.61995 130.206 1.40234 126.791 1.40234ZM126.666 9.22984H124.801V3.94628H126.561C128.719 3.94628 129.829 4.55509 129.829 6.44673C129.829 8.29489 128.781 9.22984 126.666 9.22984Z"
    fill="#526FEC" />
  <path
    d="M139.925 1.40234H134.835V17.4922H137.935V11.7738H140.009C143.34 11.7738 146 10.1213 146 6.44673C146 2.61995 143.34 1.40234 139.925 1.40234ZM139.8 9.22984H137.935V3.94628H139.695C141.852 3.94628 142.963 4.55509 142.963 6.44673C142.963 8.29489 141.915 9.22984 139.8 9.22984Z"
    fill="#526FEC" />
</svg></template>