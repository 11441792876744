export const zIndexMap = {
  TABLE_POPUP: 2,
  HEADER: 1051,
  MODAL: 1053,
  DROPDOWN: 1050,
  POPUP: 201,
  NOTIFICATION: 1099,
  MESSAGE: 3000,
  GNB_MENU: 1052,
  GNB_SCROLLBAR: 1052,
  GNB_SCROLLBAR_TRACK: 9999,
  GNB_ALERT: 1049,
  PWA_POPUP: 1054,
} as const;