import { Routes } from "@/constants/routes";
import { useQueryClient } from "@tanstack/vue-query";
import { onMounted, onUnmounted } from "vue"
import { useRoute, useRouter } from "vue-router";

export const useNetworkCheck = (type: 'online' | 'offline') => {
  const route = useRoute();
  const router = useRouter();
  const queryClient = useQueryClient();
  const handler = type === 'online' 
    ? () => { // online
      const redirect = (route.query.redirect ?? '/') as string;
      router.push(redirect.toString());
    }
    : () => { // offline
      queryClient.clear();
      const redirect = route.fullPath;
      router.replace(`${Routes.NETWORK_OFFLINE}?redirect=${redirect}`);
    };

  onMounted(() => {
    type === 'online'
      ? navigator.onLine && handler()
      : !navigator.onLine && handler();
    window.addEventListener(type, handler)
  });
  onUnmounted(() => window.removeEventListener(type, handler));
}