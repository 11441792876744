<script lang="ts">
import { PropType, computed, defineComponent, ExtractPropTypes } from 'vue';
import { tagProps } from 'ant-design-vue/lib/tag';

const useBaseProps = <T extends object>(props: object, baseProps: T) => {
  const baseKeys = Object.keys(baseProps);
  return computed(() => Object.keys(props)
      .reduce<ExtractPropTypes<T>>((a, key) => {
        if (baseKeys.includes(key)) {
          a[key as keyof ExtractPropTypes<T>] = props[key as keyof typeof props];
          return a;
        } else {
          return a;
        }
      }, {} as ExtractPropTypes<T>)
  );
}

export default defineComponent({
  props: {
    ...tagProps(),
    checkable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    size: {
      type: String as PropType<'default' | 'large'>,
      default: 'default'
    },
  },
  setup(props) {
    const baseProps = useBaseProps(props, tagProps());

    return {
      baseProps,
      props,
    }
  }
});
</script>

<template>
  <a-checkable-tag
    v-if="props.checkable"
    :class="{large: props.size === 'large'}"
    class="custom-tag"
    v-bind="baseProps"
  >
    <slot></slot>
  </a-checkable-tag>
  <a-tag
    v-else
    :class="{large: props.size === 'large'}"
    class="custom-tag"
    v-bind="baseProps"
  >
    <slot></slot>
  </a-tag>
</template>

<style lang="scss">
.custom-tag {
  display: flex;
  align-items: center;
  
  & {
    padding-inline: 8px;
    font-size: 12px;
    & span {
      font-size: 12px;
    }
  }

  &.large {
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    & span {
      font-size: 16px;
      line-height: 16px;
    }
  }
}
</style>