import { isAxiosError, AxiosError, AxiosResponse, CanceledError } from 'axios';
import z, { ZodError } from 'zod';
import { SchemaType } from './types';
import { notification } from 'ant-design-vue';
import { ERROR_NOTIFICATION_DURATION } from '@/constants/setting';

import Logger from './classes/Logger';

const logger = new Logger('api', {
  isDebug: false,
});

export const toData = <T>(res: AxiosResponse<T>) => {
  if (res.status === 204) return null;
  return res.data;
};

export const toScheme =
  <M extends z.ZodTypeAny>(model: M) =>
  (data: unknown) => {
    try {
      return model.parse(data) as SchemaType<M>;
    } catch (err) {
      const e = err as ZodError;
      logger.warn(e.errors);
      logger.capture(new Error('Schema Error'));
      notification.warn({
        message: '서버에서 예상하지 못한 형식의 값이 전달되었습니다.',
        description: '화면이 정상적으로 표시되지 않을 수 있습니다.',
        duration: ERROR_NOTIFICATION_DURATION,
      });
      return data as SchemaType<M>;
    }
  };

export const handleErrorMessage: (e: Error) => never = (e: Error) => {
  if (isAxiosError(e)) {
    const err = e as AxiosError<{
      message: string;
    }>;

    const message = err.response?.data.message;
    if (err.response?.status) {
      if (err.response?.status === 401) {
        console.warn('401');
        // TODO: 로그아웃 처리
      } else if (err.response?.status === 403) {
        console.warn('403');
        notification.error({
          message: '잘못된 요청입니다.',
          description: JSON.stringify(message ?? err.request.path),
          duration: ERROR_NOTIFICATION_DURATION,
        });
      } else if (err.response?.status >= 500) {
        notification.error({
          message: '서버에서 오류가 발생했습니다.',
          description: JSON.stringify(message),
          duration: 0,
        });
      } else {
        logger.debug(message);
        notification.error({
          message: '잘못된 요청입니다.',
          description: JSON.stringify(message ?? err.request.path),
          duration: ERROR_NOTIFICATION_DURATION,
        });
      }
    } else if (err.message === 'Network Error') {
      // TODO: 어떻게 처리할지 확인 필요
      throw err;
    } else if (err instanceof CanceledError) {
      throw err;
    } else {
      console.log(err);
      logger.capture(err);
      notification.error({
        message: '알 수 없는 오류가 발생했습니다.',
        description: JSON.stringify(message ?? err.request.path),
        duration: 0,
      });
    }

    if (message) {
      throw Error(message);
    } else {
      throw err;
    }
  }

  throw e;
};

export const handleSettlementErrorMessage: (e: Error) => never = (e: Error) => {
  if (isAxiosError(e)) {
    const err = e as AxiosError<{
      message: string;
    }>;

    const message = err.response?.data.message;
    if (err.response?.status) {
      if (err.response?.status === 401) {
        console.warn('401');
      } else if (err.response?.status === 403) {
        console.warn('403');
        notification.error({
          message: '잘못된 요청입니다.',
          description: JSON.stringify(message ?? err.request.path),
          duration: ERROR_NOTIFICATION_DURATION,
        });
      } else if (err.response?.status >= 500) {
        notification.error({
          message: '서버에서 오류가 발생했습니다.',
          description: JSON.stringify(message),
          duration: 0,
        });
      } else {
        logger.debug(message);
        notification.error({
          message: '잘못된 요청입니다.',
          description: JSON.stringify(message ?? err.request.path),
          duration: ERROR_NOTIFICATION_DURATION,
        });
      }
    } else if (err.message === 'Network Error') {
      // TODO: 어떻게 처리할지 확인 필요
      throw err;
    } else if (err instanceof CanceledError) {
      throw err;
    } else {
      console.log(err);
      logger.capture(err);
      notification.error({
        message: '알 수 없는 오류가 발생했습니다.',
        description: JSON.stringify(message ?? err.request.path),
        duration: 0,
      });
    }

    if (message) {
      throw Error(message);
    } else {
      throw err;
    }
  }

  throw e;
};

export const throwUnknownErrorMessage = (err: Error) => {
  if (!err.message) {
    throw Error('알 수 없는 오류가 발생했습니다.');
  }

  throw err;
};

export const throwUnknownErrorSettlementMessage = (err: Error) => {
  if (!err.message) {
    throw Error('알 수 없는 오류가 발생했습니다.');
  }

  throw err;
};

export const wrapQueryPromise = <T, S extends z.ZodTypeAny>(p: Promise<AxiosResponse<T>>, schema: S) => {
  return (
    p
      .then(toData)
      .then(toScheme(schema))
      .catch(handleErrorMessage)
      .catch(throwUnknownErrorMessage)
      .catch((err) => {
        throw err;
      }) ?? Promise.reject(null)
  );
};

// export const wrapMutationPromise = <T, S extends z.ZodTypeAny>(p: Promise<AxiosResponse<T>>, schema: S) => {
export const wrapMutationPromise = <T>(p: Promise<AxiosResponse<T>>) => {
  return (
    p
      .then(toData)
      // .then(toScheme(schema))
      .catch(handleErrorMessage)
      .catch(throwUnknownErrorMessage) ?? Promise.reject(null)
  );
};

const parseFilename = (contentDisposition: string) => {
  const matches = /filename\*=(?:UTF-8'')?([^;]+)/.exec(contentDisposition);
  return matches ? decodeURIComponent(matches[1]) : 'file.pdf';
};

export const blodDownloadWrapQueryPromise = <T>(p: Promise<AxiosResponse<T>>, fileNmae: string) => {
  return (
    p
      .then((res: AxiosResponse<T>) => {
        const blob = new Blob([res.data as BlobPart], { type: 'application/octet-stream' });
        const contentDisposition = res.headers['content-disposition'];
        const filename = contentDisposition ? parseFilename(contentDisposition) : fileNmae;
        const link = document.createElement('a');
        link.download = filename; // Set the filename
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(handleSettlementErrorMessage)
      .catch(throwUnknownErrorSettlementMessage) ?? Promise.reject(null)
  );
};
