<script setup lang="ts">
import { ref, computed } from 'vue';
import { InfoCircleFilled } from '@ant-design/icons-vue';
import { s } from '@/utils/style';
import { onMounted } from 'vue';

declare global {
  interface Window {
    installPrompt?: any;
  }
}

const disabled = localStorage.getItem('disablePwaInstallAlert');
const installed = ref(false);
const installPrompt = ref<any>(window.installPrompt ?? null);

const isPossibleInstall = computed(() => {
  return !disabled 
    && !installed.value
    && !window.matchMedia('(display-mode: standalone)').matches
    && !!installPrompt.value;
});

onMounted(() => {
  !window.installPrompt && window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault();
    window.installPrompt = event;
    installPrompt.value = event;
  });
});

const install = async () => {
  const result = await installPrompt.value!.prompt();
  result.outcome
  // installPrompt를 다시 사용 불가해서 결과에 상관없이(취소하더라도) alert은 종료한다.
  installed.value = true;
}

const disableChecked = ref(false);

const onClose = () => {
  if (disableChecked.value) {
    localStorage.setItem('disablePwaInstallAlert', 'true');
  }
}
  
</script>

<template>
  <div v-if="isPossibleInstall" :style="s().w('100%').p('fixed').t(0).zIndex('PWA_POPUP').v()">
    <a-alert
      type="info"
      closable
      banner
      showIcon
      class="pa-4"
      @close="onClose"
    >
      <template #message>
        해줌v를 설치해서 사용해보세요.
        <a-button size="small" type="link" style="text-decoration: underline;" @click="install">
          <a-typograhpy-text underline>설치하기</a-typograhpy-text>
        </a-button>
      </template>
      <template #icon>
        <InfoCircleFilled />
      </template>
      <template #action>
        <a-checkbox v-model:checked="disableChecked">
          다시 보지 않기
        </a-checkbox>
      </template>
    </a-alert>
  </div>
</template>