import { z } from "zod";

const transformFloat = (v: string) => parseFloat(v);

export const SocketAlertData = z.object({});

export const SocketMeterData = z.object({
  monitoring: z.object({
    group: z.record(
      z.string(), // group_id
      z.array(
        z.object({
          avg_power_1h: z.string().transform(transformFloat).nullable(),
          avg_power_15m: z.string().transform(transformFloat).nullable(),
          err_rate_1h: z.string().transform(transformFloat).nullable(),
          err_rate_15m: z.string().transform(transformFloat).nullable(),
          generation_performance_rate_15m: z.string().transform(transformFloat).nullable(),
          generation_performance_rate_1h: z.string().transform(transformFloat).nullable(),

          accumulate_meter_15m: z.string().transform(transformFloat).nullable(),
          accumulate_meter_1h: z.string().transform(transformFloat).nullable(),
          minute_meter: z.string().transform(transformFloat).nullable(),
          minute_power: z.string().transform(transformFloat).nullable(),
          target_output_achievement_rate_15m: z.string().transform(transformFloat).nullable(),
          target_output_achievement_rate_1h: z.string().transform(transformFloat).nullable(),
          target_power_1h: z.string().transform(transformFloat).nullable(),
          target_power_15m: z.string().transform(transformFloat).nullable(),
          p: z.string().transform(transformFloat).nullable(),
          
          time: z.string(),
        }),
      ),
    ).nullish(),
    resource: z.record(
      z.string(), // resource_id
      z.array(
        z.object({
          avg_power_1h: z.string().transform(transformFloat).nullable(),
          avg_power_15m: z.string().transform(transformFloat).nullable(),
          err_rate_1h: z.string().transform(transformFloat).nullable(),
          err_rate_15m: z.string().transform(transformFloat).nullable(),
          generation_performance_rate_15m: z.string().transform(transformFloat).nullable(),
          generation_performance_rate_1h: z.string().transform(transformFloat).nullable(),

          accumulate_meter_15m: z.string().transform(transformFloat).nullable(),
          accumulate_meter_1h: z.string().transform(transformFloat).nullable(),
          
          minute_meter: z.string().transform(transformFloat).nullable(),
          minute_power: z.string().transform(transformFloat).nullable(),
          target_output_achievement_rate_15m: z.string().transform(transformFloat).nullable(),
          target_output_achievement_rate_1h: z.string().transform(transformFloat).nullable(),
          target_power_1h: z.string().transform(transformFloat).nullable(),
          target_power_15m: z.string().transform(transformFloat).nullable(),
          p: z.string().transform(transformFloat).nullable(),
          time: z.string(),
          missing_data: z.union([z.literal('True'), z.literal('False')]),
        }),
      ),
    ).nullish(),
  })
})