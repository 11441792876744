import _, { forEach, isObject } from 'lodash';
import { isNullish } from './is';
import Decimal from 'decimal.js';
import { VNode, createApp } from 'vue';

export const num = (val: unknown) => (isNaN(parseFloat(`${val}`)) ? null : Number(val));
export const int = (val: unknown) => (isNaN(parseInt(`${val}`)) ? null : isNaN(parseInt(`${val}`)));
export const float = (val: unknown) => (isNaN(parseFloat(`${val}`)) ? null : isNaN(parseFloat(`${val}`)));

export const str = (val: unknown) => (isNullish(val) ? '' : String(val));

export const keys = <T extends string>(val: Record<T, unknown>) => Object.keys(val) as T[];

export const numValue = (input: string, decision: number) => {
  let dotCnt = 0;
  let floatCnt = 0;

  return input
    .replace(/[^0-9.]*/g, '')
    .replace(/^\./, '')
    .split('')
    .reduce((a, c) => {
      const isDot = c === '.';
      isDot && dotCnt++;
      dotCnt === 1 && !isDot && floatCnt++;
      if (isDot && dotCnt > 1) return a;
      if (floatCnt > decision) return a;

      a += c;
      return a;
    }, '');
};

export const toMega = (kilo: number) => {
  return Decimal.div(kilo, 1000).toNumber();
};

export const convertUnitData = (kilo: number) => {
  if (kilo < 1000) {
    return {
      value: kilo,
      unit: 'kW',
    };
  }

  return {
    value: toMega(kilo),
    unit: 'MW',
  };
};

type WithChlidrenArray<T = any> = (T & {
  children?: WithChlidrenArray;
})[];

export const flattenChildren = <T>(list: WithChlidrenArray<T>) => {
  return list.reduce((a, c) => {
    a.push(c);
    if (c.children) {
      const childList = flattenChildren(c.children);
      childList.forEach((child) => {
        a.push(child);
      });
    }
    return a;
  }, [] as T[]);
};

export const getObjNullStringsToUndefined: (val: any) => any = (val) => {
  if (isObject(val)) {
    if (Array.isArray(val)) {
      return val.map((e) => getObjNullStringsToUndefined(e));
    } else {
      const result: any = {};
      forEach(val, (e, key) => {
        result[key] = getObjNullStringsToUndefined(e);
      });
      return result;
    }
  } else {
    return val === '' ? undefined : val;
  }
};

// vue rendering과 별도로 다른 라이브러리에서 렌더링 할때 해당 클래스 가져다 사용하기 위해 class를 추출하는 함수 정의한다.
export const getClasses = (vueEl: VNode, selector: string) => {
  const tmp = document.createElement('div');
  document.body.appendChild(tmp);
  createApp(vueEl).mount(tmp);
  document.body.removeChild(tmp);

  return [...(tmp.querySelector(selector)?.classList ?? [])].join(' ');
};
