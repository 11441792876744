import { Keys } from "@/constants/key";
import { defineStore } from "pinia"
import { UnwrapRef, ref } from "vue";

export const useUserStore = defineStore(Keys.STORE.USER, () => {
  const userInfo = ref<{
    email: string,
    name: string,
    teamList: string[],
    isControllable: boolean,
  } | null>(null);

  const setUserInfo = (info: UnwrapRef<typeof userInfo>) => {
    userInfo.value = info;
  }

  return {
    userInfo,
    setUserInfo,
  }
});