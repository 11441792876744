// README: socket으로 전달받는 binary 데이터 주기는 기본 두시간이다. 단 서버에 이슈가 있을 경우 다소 늦어질 수 있다. - 2024.09.03
import { ERROR_NOTIFICATION_DURATION, WS_URL } from "@/constants/setting";
import { SocketMeterData } from "@/schema/socket.schema";
import Logger from "@/utils/classes/Logger";
import { notification } from "ant-design-vue";
// import { notification } from "ant-design-vue";
import { onBeforeMount, onUnmounted } from "vue";
import { Subject, Subscription } from 'rxjs';
import { ValueOf } from '@/utils/types';

declare global {
  interface Window {
    pako: any;
  }
}

const EventCmd = [
  'MANUAL_CONTROL_ORDER_REQUESTED'
] as const;

export const CmdHelper = (() => {
  const PREFIX = 'F__';
  function valid(str: string): str is ValueOf<typeof EventCmd> {
    return EventCmd.includes(str as ValueOf<typeof EventCmd>);
  }
  return {
    make(cmd: ValueOf<typeof EventCmd>, payload: string): string {
      return 'event:' + PREFIX + cmd + ':' + payload;
    },
    parse(receivedCmd: string): {
      cmd: ValueOf<typeof EventCmd>,
      payload: string,
    } | null {
      const [prefix, ...rest] = receivedCmd;
      if (prefix !== PREFIX) return null;

      const payload = rest.splice(rest.length-1, 1).join(':');
      const cmd = rest.join(':');

      if (!valid(cmd)) {
        return null;
      }
      return {
        cmd,
        payload,
      }
    }
  }
})();


type EventValue = 
// BiddingNotify
  | 'DAYAHEAD_BID_CREATED' // "초기_입찰서_생성"
  | 'DAYAHEAD_BID_SUBMIT' // "초기_입찰서_제출"
  | 'DAYAHEAD_MANUAL_BID_SUBMIT' // "초기_입찰서_수정_제출"
  | 'DAYAHEAD_KPX_FETCH' // "초기_입찰서_e-powermarket_갱신"
  | 'REALTIME_BID_CREATED' // "변경_입찰서_생성"
  | 'REALTIME_BID_SUBMIT' // "변경_입찰서_제출"
  | 'REALTIME_MANUAL_BID_SUBMIT' // "변경_입찰서_수정_제출"
  | 'REALTIME_KPX_FETCH' // "변경_입찰서_e-powermarket_갱신"
  | 'DAYAHEAD_SCHEDULED_METER' // "하루전_낙찰량_갱신"
  | 'REALTIME_SCHEDULED_METER' // "실시간_낙찰량_갱신"
// ControlNotify
  | 'DISPATCH_ORDER' // "급전지시"
  | 'AUTO_CONTROL_MODE_ON' // "자동제어_기능_활성화"
  | 'AUTO_CONTROL_MODE_OFF' // "자동제어_기능_비활성화"
  | 'AUTO_CONTROL_MODE_OFF_COMPLETE' // "자동제어_기능_비활성화에_따른_제어종료"
  | 'MANUAL_CONTROL_ORDER_REQUEST' // "수동제어_명령_요청"
  | 'MANUAL_CONTROL_ORDER_COMPLETE' // "수동제어_명령_완료"
// MonitoringNotify
  | 'GENERATION_STATE' // "거래시간별_발전현황"
  ;

const logger = new Logger('socket.service', {
  isDebug: false,
});

type MeterData = ReturnType<typeof SocketMeterData['parse']>;

const meterWorker: Worker = new Worker('/socket-worker.js');
let meterConnectionCnt = 0;
let receivedCnt = 0;
let curData: MeterData | null = null;

export const sender$ = new Subject<string>();

export const bidDayahead$ = new Subject<undefined>();
export const bidRealtime$ = new Subject<undefined>();
export const autoControlMode$ = new Subject<undefined>();
export const controlState$ = new Subject<undefined>();
export const controlStateWithAuto$ = new Subject<undefined>();
export const alert$ = new Subject<undefined>();
export const powerGeneration$ = new Subject<undefined>();

export const useSocketMeter = (
  handler: (data: MeterData) => void,
  errorHandler?: (err: Error) => void,
) => {
  const _url = `${WS_URL}/meter/`;

  meterWorker.onmessage = async ({data: action}) => {
    if (action.type === 'close') {
      if (meterConnectionCnt === 1) {
        connect();
      }
      return;
    } else if (action.type === 'data') {
      const origin = action.data;
      if (origin instanceof Blob) { // is meter data
        try {
          // 1분동안 새로운 실시간 데이터를 전달받지 못할 경우 화면을 센트리에 캡쳐한다.
          receivedCnt++;
          const _receivedCnt = receivedCnt;
          setTimeout(() => {
            if (meterConnectionCnt > 0 && _receivedCnt === receivedCnt) {
              const message = 'socket meter :: received error :: timeout';
              console.error(message);
              logger.capture(new Error(message));
            }
          }, 1000 * 60);
          
          const buffer = await origin.arrayBuffer();
          const result = window.pako.inflate(new Uint8Array(buffer), { to: 'string' });
          
          const parsed = JSON.parse(result);
          logger.debug(parsed);
          const data = SocketMeterData.parse(parsed);
          handler(data);
          curData = data;
        } catch (err) {
          logger.error(err);
    
          notification.warn({
            message: '서버에서 예상하지 못한 형식의 값이 전달되었습니다.',
            description: '화면이 정상적으로 표시되지 않을 수 있습니다.',
            duration: ERROR_NOTIFICATION_DURATION,
          });
    
          if (err instanceof Error) {
            errorHandler && errorHandler(err as Error);
          }
        }
      } else if (typeof origin === 'string') {
        const [cmd, ...rest] = origin.split(':');
        const data = rest.join(':');
  
        if (cmd === 'event') {
          // [TAMNA-872]
          const value = data as EventValue;

          if ([
            'DAYAHEAD_BID_CREATED',
            'DAYAHEAD_BID_SUBMIT',
            'DAYAHEAD_MANUAL_BID_SUBMIT',
            'DAYAHEAD_KPX_FETCH',
            'DAYAHEAD_SCHEDULED_METER',
          ].includes(value)) {
            // 초기 화면 업데이트
            bidDayahead$.next(undefined);
          }
  
          if ([
            'REALTIME_BID_CREATED',
            'REALTIME_BID_SUBMIT',
            'REALTIME_MANUAL_BID_SUBMIT',
            'REALTIME_KPX_FETCH',
            'DAYAHEAD_SCHEDULED_METER',
            'REALTIME_SCHEDULED_METER',
          ].includes(value)) {
            // 변경 화면 업데이트
            bidRealtime$.next(undefined);
          }
  
          if ([
            'AUTO_CONTROL_MODE_ON',
            'AUTO_CONTROL_MODE_OFF',
          ].includes(value)) {
            // 자동제어운전 업데이트
            autoControlMode$.next(undefined);
          }
  
          if ([
            'MANUAL_CONTROL_ORDER_REQUEST', // wait에 데이터를 명확하게 줄 수 있다고 백엔드 확인 완료
            'MANUAL_CONTROL_ORDER_COMPLETE',
          ].includes(value)) {
            // 제어상태 업데이트
            controlState$.next(undefined);
          }

          if ([
            'AUTO_CONTROL_MODE_OFF_COMPLETE',
          ].includes(value)) {
            // 자동제어로 인한 제어상태 업데이트
            controlStateWithAuto$.next(undefined);
          }
  
          if ([
            'DISPATCH_ORDER',
            'AUTO_CONTROL_MODE_ON',
            'AUTO_CONTROL_MODE_OFF',
            'MANUAL_CONTROL_ORDER_COMPLETE',
          ].includes(value)) {
            // 알림카드
            alert$.next(undefined);
          }
          
          if ([
            'GENERATION_STATE',
          ].includes(value)) {
            // 거래시간별 발전현황
            powerGeneration$.next(undefined);
          }
        } else if (cmd === 'message' || cmd === 'msg') {
          // [TAMNA-873]
          notification.info({
            message: '알림',
            description: data,
            duration: 0,
          });
        } else {
          // impossible
          console.warn('impossible socket data');
        }
      }
    }
  }

  let subscription: Subscription | null = null;

  const connect = () => {
    meterWorker.postMessage({
      action: 'connect',
      payload: _url,
    });

    subscription = sender$.subscribe((cmd) => {
      meterWorker.postMessage({
        action: 'send',
        payload: cmd,
      });
    })
  }

  const close = () => {
    meterWorker.postMessage({
      action: 'close',
    });
    subscription?.unsubscribe();
    subscription = null;
  }

  onBeforeMount(() => {
    if (curData !== null) {
      setTimeout(() => {
        handler(curData!);
      }, 120);
    }
    meterConnectionCnt++;
    if (meterConnectionCnt === 1) {
      connect();
    }
  });

  onUnmounted(() => {
    meterConnectionCnt--;
    if (meterConnectionCnt === 0) {
      close();
    }
  });
}