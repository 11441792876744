import { Subject, Subscription } from "rxjs";
import { onBeforeUnmount, onMounted } from "vue";


export const useSubject = <T=undefined>(subject: Subject<T>, handler: (val?: T) => void, isStartRun: boolean = false) => {
  let subscription: Subscription | null = null;

  onMounted(() => {
    isStartRun && handler(); // start run 에는 파라미터가 들어가지 않습니다.
    subscription = subject.subscribe(handler);
  });

  onBeforeUnmount(() => {
    subscription?.unsubscribe();
    subscription = null;
  });
}