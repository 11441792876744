import { computed, reactive, ref, watch } from "vue";
import { defineStore } from "pinia"
import moment from "moment";
import Decimal from "decimal.js";
import { compact, keyBy } from "lodash";

import { Keys } from "@/constants/key";
import { AlertType } from "@/constants/const";
import { Apis, IdLiteral } from "@/constants/api";

import { useRequestStore } from "@/store/useRequestStore";

import { wrapQueryPromise } from "@/utils/api";
import Logger from "@/utils/classes/Logger";
import { getSection } from "@/utils/getter";

import { SchemaType } from "@/utils/types";
import { GroupTotalResponseData } from "@/schema/api/resource.schema";
import { Alert, GeneratedCardState, GetChangedCardState, GetItem } from "@/interfaces/alert";

import { useAuthPage } from "@/hooks/useAuthPage";
import { useSubject } from "@/hooks/useSubject";
import { alert$ } from "@/hooks/services/socket.service";
import { useInterval } from "@/hooks/useInterval";
import { useGroupList } from "@/hooks/services/resource.service";

import { GroupControlOrder, GroupControlState } from "@/schema/api/control.schema";
import { BidRealtimeResponseData } from "@/schema/api/bid.schema";

const logger = new Logger('card', {
  isDebug: false,
});

const getNow = () => moment();

export const useAlertStore = defineStore(Keys.STORE.ALERT, () => {
  const { isAuthorized } = useAuthPage();
  const groupList = useGroupList();

  const alerts = reactive<Record<number, Alert[]>>({});

  const curSection = computed(() => {
    return getSection(moment());
  });

  const selectedGroupId = ref(-1);
  const setSelectedGroupId = (groupId: number) => {
    selectedGroupId.value = groupId;
  }

  const exposeAlerts = computed<Alert[] | null>(() => {
    return alerts[selectedGroupId.value] ?? null;
  });

  const isExistAlert = computed(() => {
    return Object.keys(alerts).reduce((a, groupId) => {
      const c = alerts[+groupId];
      return a || c.filter((e) => e.control.type !== AlertType.NONE).length > 0;
    }, false);
  });

  const { $get } = useRequestStore();

  const biddingCapture = {
    data: null as SchemaType<typeof BidRealtimeResponseData> | null,
    bidAt: null as string | null,
    limit: null as moment.Moment | null,
  };
  const getWinning = async (groupId: string, section: ReturnType<typeof getSection>) => {
    let bidding: SchemaType<typeof BidRealtimeResponseData> | null = biddingCapture.data;

    const now = getNow();
    const bidAt = section.start.format('YYYY-MM-DD');
    if (bidding === null 
      || now.isAfter(biddingCapture.limit) 
      || (bidAt !== biddingCapture.bidAt)) {
      biddingCapture.limit = now.clone().add(60, 'seconds'); // 60초동안 caching
      biddingCapture.bidAt = bidAt;
      biddingCapture.data = await wrapQueryPromise(
        $get(Apis.GROUP__$1__BIDDING__REALTIME.replace(IdLiteral.GROUP_ID, groupId), {
          params: {
            is_algorithm: true,
            bid_at: section.start.format('YYYY-MM-DD'),
          },
        }),
        BidRealtimeResponseData,
      );

      bidding = biddingCapture.data;
    }
    
    // hourSection은 index(0,1,2,3)로 생성하고 있어서 -1 안합니다.
    // 값이 MW 단위로 전달받기 때문에 1000을 곱해서 kW 단위로 변경한다.
    const _winning = bidding?.rt_se[section.hourSection[0]-1][section.hourSection[1]] ?? null;
    if (_winning === null) return _winning;
    return _winning * 1000;
  }

  const getControlData = async (groupId: string, section: ReturnType<typeof getSection>) => {
    const controlState = await wrapQueryPromise(
      $get(Apis.GROUP__$1__CONTROL_STATE.replace(IdLiteral.GROUP_ID, groupId), {
        params: {
          bid_at: section.start.format('YYYY-MM-DD'),
          hour: section.hourSection[0],
          section: section.hourSection[1],
        },
      }),
      GroupControlState,
    ).then((res) => {
      const realDict = keyBy(res.real, 'r_id')
      return res.mock.map(e => ({
        r_id: e.r_id,
        // target_power는 real에 값이 있으면 real 값을 사용한다.
        target_power: realDict[e.r_id] ? realDict[e.r_id].target_power : e.target_power,
        is_real_control: !!realDict[e.r_id],
        is_auto: realDict[e.r_id] ? realDict[e.r_id].is_auto : null,
        input_meter: e.input_meter,
        time: new Date(e.time).getTime(),
      }));
    });

    
    // input_meter는 kW로 온다고 가정한다.
    const inputMeterSum = controlState.length > 0 
      ? Decimal.sum(...controlState.map(control => control.input_meter ? Decimal.sum(...(compact(control.input_meter ?? [])).concat(0)) : 0))
      : 0;
    const avgPower = Decimal.div(inputMeterSum, (controlState[0]?.input_meter?.length || 1)).toNumber();
    const isRealControl = controlState.reduce((a, c) => (c.is_auto && c.is_real_control) || a, false) // 알고리즘에 의해 실제로 제어되고 있는지 확인

    const targetPower = isRealControl ? controlState.reduce((a, c) => {
      let targetPower = c.target_power; // target_power는 real에 값이 있으면 real 값을 사용한다.
      if (targetPower === null) {
        // mock real target_power가 모두 없다면 input_meter의 평균값을 사용한다.
        targetPower = Decimal.div(Decimal.sum(...c.input_meter.concat(0)), c.input_meter.length).toNumber();
      }

      return a + targetPower ;
    }, 0) : null;

    // 가장 최신 time을 이 controlData에 시간으로 본다.
    const time = controlState.reduce((a, c) => a > c.time ? a : c.time, 0);

    return {
      avgPower,
      isRealControl,
      targetPower,
      time: time === 0 ? null : new Date(time),
    }
  }

  const getControlOrder = (groupId: string) => {
    return wrapQueryPromise(
      $get(Apis.GROUP__$1__CONTROL_ORDER.replace(IdLiteral.GROUP_ID, groupId)),
      GroupControlOrder,
    ).then((controlOrder) => {
      if (controlOrder?.target_power) {
        // w => kW
        controlOrder.target_power = controlOrder.target_power / 1000;
      }
      return controlOrder;
    });
  }

  const groupListCapture = {
    data: null as SchemaType<typeof GroupTotalResponseData> | null,
    limit: null as moment.Moment | null,
    processing: false,
  };

  const getGroupName = async (groupId: string) => {
    let groupList: SchemaType<typeof GroupTotalResponseData> | null = groupListCapture.data; 
    const now = getNow();
    if (groupList === null || now.isAfter(groupListCapture.limit)) {
      groupListCapture.processing = true;

      groupListCapture.limit = now.clone().add(20, 'seconds'); // 20초동안 caching
      groupListCapture.data = await wrapQueryPromise(
        $get(Apis.GROUP__TOTAL), GroupTotalResponseData
      );

      groupList = groupListCapture.data;
      groupListCapture.processing = false;
    } else if (groupListCapture.processing) {
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!groupListCapture.processing) {
            clearInterval(interval);
            groupList = groupListCapture.data; 
            resolve(null);
          }
        }, 1000);
      });
    }

    return groupList?.filter((e) => e.id === Number(groupId))[0]?.name ?? '';
  }

  const getGeneratedCardState: (args: {
    section: ReturnType<typeof getSection>,
    avgPower: number,
    winning: number | null,
  }) => GeneratedCardState = (args) => {
    
    const {
      section,
      avgPower,
      winning,
    } = args;

    const _logger = logger.scope('generate').scope(`section[${section.hourSection[0]}, ${section.hourSection[1]+1}]`);
    _logger.info('%c==== start ====', 'color: gray');
    
    _logger.scope('평균출력').info(avgPower);
    _logger.scope('실시간 낙찰량').info(winning);

    _logger.scope(`if. 실시간 낙찰량이 존재하는가?`).info(winning !== null);
    if (winning === null) {
      _logger.scope('result').info('%c미노출', 'color: gray;');
      return '미노출';
    }

    _logger.scope(`if. 평균출력값 > 실시간 낙찰량`).info(avgPower > winning);
    if (avgPower > winning) {
      _logger.scope('result').info('%c자기제약 %c미제어', 'color: blue;', 'color: gray;');
      return '자기제약/미제어';
    } else {
      _logger.scope('result').info('%c미노출', 'color: gray;');
      return '미노출';
    }
  }

  const getChangedCardState: GetChangedCardState = (args) => {
    const {
      section,
      cardState,
      controlOrder,
      avgPower,
      winning,
      isRealControl,
    } = args;

    // 현재구간 급전지시 수신여부
    const isInstruction = controlOrder.status === 'dispatch_order';
    const isSetPoint = controlOrder.status_confirmed; // 급전지시를 수신했는가?에 대한 값 (제어알림 표현 로직에서)

    const _logger = logger.scope('change  ').scope(`section[${section.hourSection[0]}, ${section.hourSection[1]+1}]`);
    _logger.info('%c==== start ====', 'color: gray');

    _logger.scope('변경전 카드 상태').info(cardState);

    _logger.scope('평균출력').info(avgPower);
    _logger.scope('실시간 낙찰값').info(winning);
    _logger.scope('실제 제어 여부').info(isRealControl);

    _logger.scope(`if. 실시간 낙찰량이 존재하는가?`).info(winning !== null);
    if (winning === null) {
      _logger.scope('result').info('%c미노출', 'color: gray;');
      return '미노출';
    }

    _logger.scope(`if. 변경전 카드가 자기제약/미제어 상태인가?`).info(cardState === '자기제약/미제어');
    if (cardState === '자기제약/미제어') {
      _logger.scope(`if. 급전지시값 수신했는가?`).info(isSetPoint);
      if (isSetPoint) {
        const dispatchOrderTargetPower = controlOrder?.target_power;
        _logger.scope('급전지시값').info(dispatchOrderTargetPower);
        _logger.scope(`if. 급전지시값과 실시간 낙찰값이 다른가? (수동 급전지시 상황인가?)`).info(isInstruction);
        if (isInstruction) { // 급전지시
          _logger.scope(`if. 평균출력 > 급전지시값`).info(avgPower > dispatchOrderTargetPower!);
          if (avgPower > dispatchOrderTargetPower!) {
            _logger.scope(`if. 알고리즘을 통해 제어 필요한가?`).info(isRealControl);
            if (isRealControl) {
              _logger.scope('result').info('%c급전지시 %c제어', 'color: red;', 'color: red;');
              return '급전지시/제어';
            } else {
              _logger.scope('result').info('%c급전지시 %c미제어', 'color: red;', 'color: gray;');
              return '급전지시/미제어';
            }
          } else {
            _logger.scope('result').info('%c급전지시 %c미제어 %c(비활성)', 'color: red;', 'color: gray;', '');
            return '급전지시/미제어/비활성';
          }
        } else { // 자기제약
          _logger.scope(`if. 평균출력 > 낙찰량`).info(avgPower > winning);
          if (avgPower > winning) {
            _logger.scope(`if. 알고리즘을 통해 제어 필요한가?`).info(isRealControl);
            if (isRealControl) {
              _logger.scope('result').info('%c자기제약 %c제어', 'color: blue;', 'color: blue;');
              return '자기제약/제어';
            } else {
              _logger.scope('result').info('%c자기제약 %c미제어', 'color: blue;', 'color: gray;');
              return '자기제약/미제어';
            }
          } else {
            _logger.scope('result').info('%c자기제약 %c미제어 %c(비활성)', 'color: blue;', 'color: gray;', 'color: gray;');
            return '자기제약/미제어/비활성';
          }
        }
      } else {
        _logger.scope('result').info('%c자기제약 %c미제어 %c(유지)', 'color: blue;', 'color: gray;', 'color: gray;');
        return '자기제약/미제어/유지';
      }
    } else {
      _logger.scope(`if. 급전지시값 수신했는가?`).info(isSetPoint);
      if (isSetPoint) {
        const dispatchOrderTargetPower = controlOrder?.target_power;
        _logger.scope('급전지시값').info(dispatchOrderTargetPower);
        // _logger.scope(`if. 급전지시값과 실시간 낙찰값이 다른가?`).info(dispatchOrderTargetPower !== winning);
        _logger.scope(`if. 급전지시값과 실시간 낙찰값이 다른가? (수동 급전지시 상황인가?)`).info(isInstruction);
        if (isInstruction) { // 급전지시
          _logger.scope(`if. 평균출력 > 급전지시값`).info(avgPower > dispatchOrderTargetPower!);
          if (avgPower > dispatchOrderTargetPower!) {
            _logger.scope(`if. 알고리즘을 통해 제어 필요한가?`).info(isRealControl);
            if (isRealControl) {
              _logger.scope('result').info('%c급전지시 %c제어', 'color: red;', 'color: red;');
              return '급전지시/제어';
            } else {
              _logger.scope('result').info('%c급전지시 %c미제어', 'color: red;', 'color: gray;');
              return '급전지시/미제어';
            }
          } else {
            _logger.scope('result').info('%c급전지시 %c미제어 %c(비활성)', 'color: red;', 'color: gray;', 'color: gray;');
            return '급전지시/미제어/비활성';
          }
        } else { // 자기제약
          _logger.scope(`if. 평균출력 > 낙찰값`).info(avgPower > winning); // 자기제약 상태에서는 급전지시값을 수신하지 않으므로 낙찰값과 비교 (제어알림 표현 로직과 다름)
          if (avgPower > winning) {
            _logger.scope(`if. 알고리즘을 통해 제어 필요한가?`).info(isRealControl);
            if (isRealControl) {
              _logger.scope('result').info('%c자기제약 %c제어', 'color: blue;', 'color: blue;');
              return '자기제약/제어';
            } else {
              _logger.scope('result').info('%c자기제약 %c미제어', 'color: blue;', 'color: gray;');
              return '자기제약/미제어';
            }
          } else {
            _logger.scope('result').info('%c미노출 %c(유지)', 'color: gray;', 'color: gray;');
            return '미노출';
          }
        }
      } else {
        _logger.scope('result').info('%c미노출 %c(유지)', 'color: gray;', 'color: gray;');
        return '미노출/유지';
      }
    }
  }

  const getItem: GetItem = (state, data) => {
    const {
      name,
      section,
      target,
      controlOrder,
      avgPower,
      winning,
      controlTime,
    } = data;

    switch(state) {
      case '미노출':
      case '미노출/유지':
        return {
          name,
          control: {
            type: AlertType.NONE,
            start: section.start.toDate(),
            end: section.end.toDate(),
            hourSection: section.hourSection,
            target,
            isControl: false,
          },
          avgPower,
          winning,
          dispatchOrderTarget: null,
          disabled: false,
        };
      case '급전지시/미제어':
        return {
          name,
          control: {
            type: AlertType.INSTRUCTION,
            start: section.start.toDate(),
            end: section.end.toDate(),
            hourSection: section.hourSection,
            target,
            isControl: false,
          },
          avgPower,
          winning,
          dispatchOrderTarget: controlOrder?.target_power ?? null,
          disabled: false,
        };
      case '급전지시/제어':
        return {
          name,
          control: {
            type: AlertType.INSTRUCTION,
            start: section.start.toDate(),
            end: section.end.toDate(),
            hourSection: section.hourSection,
            target,
            isControl: true,
          },
          avgPower,
          winning,
          dispatchOrderTarget: controlOrder?.target_power ?? null,
          disabled: false,
        };
      case '급전지시/미제어/비활성':
        return {
          name,
          control: {
            type: AlertType.INSTRUCTION,
            start: section.start.toDate(),
            end: section.end.toDate(),
            hourSection: section.hourSection,
            isControl: false,
            target,
          },
          avgPower,
          winning,
          dispatchOrderTarget: controlOrder?.target_power ?? null,
          disabled: true,
        };
      case '자기제약/미제어':
      case '자기제약/미제어/유지':
        return {
          name,
          control: {
            type: AlertType.LIMITATION,
            start: section.start.toDate(),
            end: section.end.toDate(),
            hourSection: section.hourSection,
            isControl: false,
            target,
            updatedAt: controlTime,
          },
          dispatchOrderTarget: null,
          avgPower,
          winning,
          controlOrder,
          disabled: false,
        };
      case '자기제약/제어':
        return {
          name,
          control: {
            type: AlertType.LIMITATION,
            start: section.start.toDate(),
            end: section.end.toDate(),
            hourSection: section.hourSection,
            isControl: true,
            target,
            updatedAt: controlTime,
          },
          avgPower,
          winning,
          dispatchOrderTarget: null,
          disabled: false,
        };
      case '자기제약/미제어/비활성':
        return {
          name,
          control: {
            type: AlertType.LIMITATION,
            start: section.start.toDate(),
            end: section.end.toDate(),
            hourSection: section.hourSection,
            isControl: false,
            target,
            updatedAt: controlTime,
            // updatedAt: dispatchOrder.request_at ? new Date(dispatchOrder.request_at) : null,
          },
          avgPower,
          winning,
          dispatchOrderTarget: null,
          disabled: true,
        };
    }
  }

  async function init(now: moment.Moment, groupId: number): Promise<void> {
    if (!isAuthorized()) {
      return;
    }

    logger.info('init');
    const groupIdStr = groupId+'';

    const section = getSection(now);
    const nextSection = section.next();
    
    const controlOrder = await getControlOrder(groupIdStr);
    logger.scope('init').scope('api/controlOrder').info(controlOrder);
    

    const { avgPower, isRealControl, targetPower, time: controlTime } = await getControlData(groupIdStr, section);
    const {avgPower: prevAvgPower, time: prevControlTime } = await getControlData(groupIdStr, section.prev());


    const winning = getWinning(groupIdStr, section);
    const nextWinning = getWinning(groupIdStr, nextSection);
    const groupName = getGroupName(groupIdStr);

    const curCardState = getChangedCardState({
      cardState: getGeneratedCardState({
        section,
        avgPower: prevAvgPower,
        winning: await winning,
      }),
      section,
      avgPower,
      winning: await winning,
      controlOrder,
      isRealControl,
    });
    
    const nextCardState = getGeneratedCardState({
      section: nextSection,
      avgPower,
      winning: await nextWinning,
    });

    const name = await groupName;
    // 현재카드는 유지 상황에서는 
    const curItem = (curCardState === '미노출/유지' || curCardState === '자기제약/미제어/유지')
      ? getItem(curCardState, {
          name,
          section: section,
          target: targetPower,
          controlOrder,
          avgPower: prevAvgPower,
          winning: await winning,
          controlTime: prevControlTime,
        })
      : getItem(curCardState, {
          name,
          section: section,
          target: targetPower,
          controlOrder,
          avgPower,
          winning: await winning,
          controlTime,
        });

    const nextItem = getItem(nextCardState, {
      name,
      section: nextSection,
      target: null,
      controlOrder,
      avgPower,
      winning: await nextWinning,
      controlTime,
    });

    alerts[groupId] = [
      curItem,
      nextItem,
    ];
  }

  useSubject(
    alert$, 
    () => {
      groupList.data.value?.forEach((group) => {
        logger.info('group', group);
        init(getNow(), group.id);
      });
    },
    true,
  );

  watch(groupList.data, (groupList) => {
    groupList?.forEach((group) => {
      logger.info('group', group);
      init(getNow(), group.id);
    });
  }, {
    immediate: true,
  });

  const now = moment();
  const nowMinutes = now.get('minutes');
  useInterval(() => {
    const now = moment().startOf('minutes');
    for (const groupId in alerts) {
      alerts[groupId] = alerts[groupId].filter((e) => moment(e.control.start).startOf('minutes').isSameOrAfter(now));
    }
  }, 60 * 15, moment().set('minutes', nowMinutes - (nowMinutes % 15) + 15).startOf('minutes'));

  return {
    curSection,
    exposeAlerts,
    isExistAlert,
    setSelectedGroupId,
  };
});