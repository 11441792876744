import { SETTLEMENT_API_URL } from '@/constants/setting';

export const IdLiteral = Object.freeze({
  RESOURCE_ID: ':resource-id',
  GROUP_ID: ':group-id',
  VARIABLE_ID: ':variable_id',
  INVERTER_ID: ':inverter_id',
  ESS_ID: ':ess_id',
  CONTROL_ID: ':control_id',
  PLANT_ID: 'plant_id',
  HAEZOOM_NUMBER: 'haezoom_number',
  MEMBER_ID: 'member_id',
  COMPANY_PAYMENTS_ID: 'company_payments_id',
});

const settlementApis = Object.freeze({
  /**
   * 정산 자원관리 token api
   * POST
   */
  SETTLEMENT__GET_TOKEN: `${SETTLEMENT_API_URL}/account/token/`,
  SETTLEMENT__GET_REFRESH: `${SETTLEMENT_API_URL}/account/token/refresh/`,
  // SETTLEMENT__GET_TOKEN: `https://settlement.dev.vpphaezoom.com/account/token/`,
  // SETTLEMENT__GET_REFRESH: `https://settlement.dev.vpphaezoom.com/token/refresh/`,
  /**
   * @template $1 IdLiteral.RESOURCE_ID
   * 정산 자원관리 정산정보 입력 확인 api
   * POST
   */
  SETTLEMENT__GET_PLANTS: `${SETTLEMENT_API_URL}/settlement/plants/`,
  SETTLEMENT__$1__GET_SETTLEMENTINFO_LIST: `${SETTLEMENT_API_URL}/contract/`,
  SETTLEMENT__POST_CREATE_SETTLEMENTINFO: `${SETTLEMENT_API_URL}/contract/`,
  SETTLEMENT__$1__GET_SETTLEMENT_INFO: `${SETTLEMENT_API_URL}/contract/${IdLiteral.PLANT_ID}/`,
  SETTLEMENT__$1_GET_HAEZOOM_NUMBER_CHECK: `${SETTLEMENT_API_URL}/contract/check/${IdLiteral.HAEZOOM_NUMBER}/`,
  SETTLEMENT__$1_DELETE_MEMBERS: `${SETTLEMENT_API_URL}/contract/members/${IdLiteral.MEMBER_ID}/`,
  SETTLEMENT__$1__SETTLEMENT_PDF_INFO: `${SETTLEMENT_API_URL}/settlement/company-payments/${IdLiteral.COMPANY_PAYMENTS_ID}/`,
  SETTLEMENT__GET_INVOICE_LIST: `${SETTLEMENT_API_URL}/settlement/company-payments/`,
  SETTLEMENT__PATCH_INVOICE_LIST: `${SETTLEMENT_API_URL}/settlement/company-payments/received/`,
  SETTLEMENT__$1__GET_PDF_DOWNLOAD: `${SETTLEMENT_API_URL}/settlement/company-payments/download/`,
  SETTLEMENT__POST_INVOICE_LIST: `${SETTLEMENT_API_URL}/settlement/invoices/`,

  // SETTLEMENT__1__GET_SETTLEMENTINFO_LIST: `https://settlement.dev.vpphaezoom.com/api/contract/`,
  // SETTLEMENT__$1__GET_SETTLEMENT_INFO: `https://settlement.dev.vpphaezoom.com/api/contract/${IdLiteral.PLANT_ID}/`,
  // SETTLEMENT__GET_PLANTS: `https://settlement.dev.vpphaezoom.com/api/settlement/plants/`,

  /**
   * @template $1 IdLiteral.RESOURCE_ID
   * 정산 발송 api vppmaster 가져옴.
   * GET
   */
  SETTLEMENT__$1__SEND_LIST: `http://localhost:3000/api/settlement/send-list/`,
  SETTLEMENT__$1__SEND_DEFAULT_TEMPLATE: `http://localhost:3000/api/settlement/default-template/`,
});

export const Apis = Object.freeze({
  ...settlementApis,

  USER__TOKEN: `/user/token/`,
  USER__TOKEN__REFRESH: `/user/token/refresh/`,
  // '/api/meta/vpp/'를 field없이 요청하면 사용할 수 있는 필드를 확인할 수 있다.
  META: `/meta/vpp/?field=`
    + [
      'resource-type',
      'resource-variable',
      'group-status',
      'group-grid',
      'install-type',
      'module-type',
      'resource-contract-type',
      'resource-fixed-contract-type',
      'resource-contract-date',
    ].join(','),
  LIST__SUBSTATION: `/list/substation/`,
  LIST__DL: `/list/dl/`,
  LIST__MONITORING: `/list/monitoring/`,
  LIST__CONTROL: `/list/control/`,
  /************************
   * 📝 자원 관련 정보
   ************************/
  /**
   * @body {...ResourceRegistRequestData}
   */
  RESOURCES: `/resource/`,
  /**
   * @template IdLiteral.RSOURCE_ID
   */
  RESOURCES__$1: `/resource/${IdLiteral.RESOURCE_ID}/`,

  /**
   * @template IdLiteral.RSOURCE_ID
   * @template IdLiteral.INVERTER_ID
   */
  RESOURCE__$1__INVERTER__$2: `resource/${IdLiteral.RESOURCE_ID}/inverter/${IdLiteral.INVERTER_ID}/`,
  /**
   * @template IdLiteral.RSOURCE_ID
   * @template IdLiteral.ESS_ID
   */
  RESOURCE__$1__ESS__$2: `resource/${IdLiteral.RESOURCE_ID}/ess/${IdLiteral.ESS_ID}/`,
  /**
   * @template IdLiteral.RSOURCE_ID
   * @template IdLiteral.CONTROL_ID
   */
  RESOURCE__$1__CONTROL__$2: `resource/${IdLiteral.RESOURCE_ID}/control/${IdLiteral.CONTROL_ID}/`,

  RESOURCE__TOTAL_PARTICIPATING_CAPACITY: `/resource/total-participating-capacity/`,
  /**
   * @template IdLiteral.RSOURCE_ID
   * */
  RESOURCE__PARTICIPATING_CAPACITY: `/resource/${IdLiteral.RESOURCE_ID}/participating-capacity/`,
  /**
   * @template IdLiteral.RSOURCE_ID
   * @template IdLiteral.VARIABLE_ID
   * */
  RESOURCE__PARTICIPATING_CAPACITY_DETAIL: `/resource/${IdLiteral.RESOURCE_ID}/participating-capacity/${IdLiteral.VARIABLE_ID}/`,

  GROUP__TOTAL: `/group/total/`,
  GROUP__STATUS: `/group/status/`,

  /**
   * 집합자원에 포함된 보유자원에 대한 평균 오차율
   * @param {'YYYY-MM-DD'} bid_at
   * */
  GROUP__$1__RESOURCE__ERR_RATE: `/group/${IdLiteral.GROUP_ID}/resource/err-rate/`,

  /**
   * 보유자원 집합자원 할당 목록
   * @param {'YYYY-MM-DD'} date
   * @body {GroupAssginRequestData}
   * */
  GROUP__ASSIGN: `/group/assign/`,

  /**
   * 집합자원 중 자원 목록
   * @template $1 IdLiteral.GROUP_ID
   * @param {'YYYY-MM-DD'} date
   * */
  GROUP__$1__RESOURCE: `/group/${IdLiteral.GROUP_ID}/resource/`,

  /************************
   * 📝 입찰 관련 정보
   * 요청한 날짜에 대해 유효한 값을 받는다.
   * 11월 12일을 요청할 경우 하루전(dayahead)이라면 11월 11일에 입찰 실행한 데이터를 받는다.
   ************************/
  /**
   * 입찰 상태 (하루 전)
   * @template $1 IdLiteral.GROUP_ID
   * @param {'YYYY-MM-DD'} bid_at
   * */
  GROUP__$1__BIDDING__DAYAHEAD__SUBMIT_STATE: `/group/${IdLiteral.GROUP_ID}/bidding/dayahead/submit-state/`,
  /**
   * 입찰 상태 (실시간)
   * @template $1 IdLiteral.GROUP_ID
   * @param {'YYYY-MM-DD'} bid_at
   * @param {'YYYY-MM-DD'} hour
   * */
  GROUP__$1__BIDDING__REALTIME__SUBMIT_STATE: `/group/${IdLiteral.GROUP_ID}/bidding/realtime/submit-state/`,
  /**
   * 입찰서 (하루 전)
   * @template $1 IdLiteral.GROUP_ID
   * @param {boolean} is_algorithm
   * @param {'YYYY-MM-DD'} bid_at
   * * POST (수동제출)
   * @body {} bidding
   */
  GROUP__$1__BIDDING__DAYAHEAD: `/group/${IdLiteral.GROUP_ID}/bidding/dayahead/`,
  /**
   * 입찰서 정보 (하루 전)
   * @template $1 IdLiteral.GROUP_ID
   * @param {boolean} is_algorithm
   * @param {'YYYY-MM-DD'} bid_at
   * * POST (수동제출)
   * @body {} bidding
   */
  GROUP__$1__BIDDING__DAYAHEAD__INFO: `/group/${IdLiteral.GROUP_ID}/bidding/dayahead/info/`,
  /**
   * 입찰서 (실시간)
   * @template $1 IdLiteral.GROUP_ID
   * @param {boolean} is_algorithm
   * @param {'YYYY-MM-DD'} bid_at
   * * POST (수동제출)
   * @body {} bidding
   */
  GROUP__$1__BIDDING__REALTIME: `/group/${IdLiteral.GROUP_ID}/bidding/realtime/`,
  /**
   * 입찰서 (실시간)
   * @template $1 IdLiteral.GROUP_ID
   * @param {boolean} is_algorithm
   * @param {'YYYY-MM-DD'} bid_at
   * * POST (수동제출)
   * @body {} bidding
   */
  GROUP__$1__BIDDING__REALTIME__INFO: `/group/${IdLiteral.GROUP_ID}/bidding/realtime/info/`,
  /**
   * 입찰 공급가능 용량 (하루 전)
   * @template $1 IdLiteral.GROUP_ID
   * GET
   * @param {boolean} is_algorithm
   * @param {'YYYY-MM-DD'} bid_at
   * POST
   * @body {Record<string, (number | null)[]>} bidding
   */
  GROUP__$1__RESOURCE__BIDDING__DAYAHEAD: `/group/${IdLiteral.GROUP_ID}/resource/bidding/dayahead/`,
  /**
   * 입찰 공급가능 용량 (하루 전)
   * @template $1 IdLiteral.GROUP_ID
   * GET
   * @param {boolean} is_algorithm
   * @param {'YYYY-MM-DD'} bid_at
   * POST
   * @body {Record<string, (number | null)[]>} bidding
   */
  GROUP__$1__RESOURCE__BIDDING__REALTIME: `/group/${IdLiteral.GROUP_ID}/resource/bidding/realtime/`,
  /**
   * @template $1 IdLiteral.GROUP_ID
   * POST
   * @body {boolean} is_algorithm
   * @body {'YYYY-MM-DD'} bid_at
   * @body {(number | null)[]} section_10_bidding length: 24
   */
  GROUP__$1__BIDDING__DAYAHEAD__DISTRIBUTE: `/group/${IdLiteral.GROUP_ID}/bidding/dayahead/distribute/`,
  /**
   * @template $1 IdLiteral.GROUP_ID
   * POST
   * @body {boolean} is_algorithm
   * @body {'YYYY-MM-DD'} bid_at
   * @body {(number | null)[]} section_10_bidding length: 24
   */
  GROUP__$1__BIDDING__REALTIME__DISTRIBUTE: `/group/${IdLiteral.GROUP_ID}/bidding/realtime/distribute/`,

  /**
   * @template $1 IdLiteral.GROUP_ID
   * GET
   * @param {'YYYY-MM-DD'} bid_at
   * @param {'1h' | '15m'} resolution
   */
  GROUP__$1__GENERATION_STATE: `/group/${IdLiteral.GROUP_ID}/generation-state/`,
  /**
   * @template $1 IdLiteral.RESOURCE_ID
   * GET
   * @param {'YYYY-MM-DD'} bid_at
   * @param {'1h' | '15m'} resolution
   */
  RESOURCE__$1__GENERATION_STATE: `/resource/${IdLiteral.RESOURCE_ID}/generation-state/`,

  //
  /**
   * @template $1 IdLiteral.GROUP_ID
   * GET
   * 현재 구간에서 제어중인 자원에 대한 상태 값을 전달 받는다.
   */
  GROUP__$1__CONTROL_STATE: `/group/${IdLiteral.GROUP_ID}/control-state/`,

  /**
   * @template $1 IdLiteral.RESOURCE_ID
   * POST
   * 자원에 수동제어 상태를 변경한다.
   */
  RESOURCE__$1__CONTROL__ON: `/resource/${IdLiteral.RESOURCE_ID}/control/on/`,
  RESOURCE__$1__CONTROL__OFF: `/resource/${IdLiteral.RESOURCE_ID}/control/off/`,
  RESOURCE__$1__CONTROL__MOP__ON: `/resource/${IdLiteral.RESOURCE_ID}/control/mop/on/`,
  RESOURCE__$1__CONTROL__MOP__OFF: `/resource/${IdLiteral.RESOURCE_ID}/control/mop/off/`,

  /**
   * @template $1 IdLiteral.RESOURCE_ID
   * meter 수집에서 마지막 정보 값을 받아온다.
   * (마지막 수집 + 1분이 오류 누락 발생 시간)
   * GET
   */
  RESOURCE__$1__METER_LAST_INFO: `/resource/${IdLiteral.RESOURCE_ID}/meter-last-info/`,

  /**
   * @template $1 IdLiteral.GROUP_ID
   * GET
   * 현재 구간에서 제어중인 자원에 대한 상태 값을 전달 받는다.
   */
  GROUP__$1__BIDDING__LOWER_LIMIT_PRICE: `/group/${IdLiteral.GROUP_ID}/bidding/lower-limit-price/`,

  /**
   * @template $1 IdLiteral.GROUP_ID
   * GET
   * 요청시간 기준 수동급전지시(dispatchOrder) 관련 데이터를 반환한다. (낙찰량과 비교하여 급전지시/자기제약을 판단하기 위한 급전지시 데이터 수신)
   */
  GROUP__$1__DISPATCH_ORDER: `/group/${IdLiteral.GROUP_ID}/dispatch-order/`,

  /**
   * @template $1 IdLiteral.GROUP_ID
   * GET
   * 자동제어 상태를 반환한다.
   */
  GROUP__$1__AUTO_CONTROL_STATE: `/group/${IdLiteral.GROUP_ID}/auto-control-state/`,

  /**
   * @template $1 IdLiteral.GROUP_ID
   * POST
   * 자동제어 상태를 활성화 한다.
   */
  GROUP__$1__AUTO_CONTROL__ON: `/group/${IdLiteral.GROUP_ID}/auto-control/on/`,

  /**
   * @template $1 IdLiteral.GROUP_ID
   * POST
   * 자동제어 상태를 비활성화 한다.
   */
  GROUP__$1__AUTO_CONTROL__OFF: `/group/${IdLiteral.GROUP_ID}/auto-control/off/`,

  /**
   * 요청시간 기준 급전지시(setPoint) 관련 데이터를 반환한다. (급전지시를 수신했는가?)
   */
  GROUP__$1__SELF_CONTROL_ORDER: `/group/${IdLiteral.GROUP_ID}/self-control-order/`,
  /**
   * 요청시간 기준 급전지시(setPoint) 관련 데이터를 반환한다. (급전지시를 수신했는가?)
   */
  GROUP__$1__CONTROL_ORDER: `/group/${IdLiteral.GROUP_ID}/control-order/`,
});

export const CommonApis = Object.freeze({
  BIDDING_MARKET__SMP__DAYAHEAD: `/market/smp/dayahead`,
  BIDDING_MARKET__SMP__REALTIME: `/market/smp/realtime`,
});
