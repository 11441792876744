import { Apis } from "@/constants/api";
import { ControlResponseData, DlResponseData, MetaResponseData, MonitoringResponseData, SubstationResponseData } from "@/schema/api/list.schema";
import { useRequestStore } from "@/store/useRequestStore";
import { wrapQueryPromise } from "@/utils/api";
import { useQuery } from "@tanstack/vue-query";
import { reactive } from "vue";

export const useListMetas = () => {
  const { $get } = useRequestStore();

  const substation = useQuery({
    queryKey: [Apis.LIST__SUBSTATION],
    queryFn: () => wrapQueryPromise($get(Apis.LIST__SUBSTATION), SubstationResponseData),
    staleTime: Infinity,
  });

  const dl = useQuery({
    queryKey: [Apis.LIST__DL],
    queryFn: () => wrapQueryPromise($get(Apis.LIST__DL), DlResponseData),
    staleTime: Infinity,
  });

  const monitoring = useQuery({
    queryKey: [Apis.LIST__MONITORING],
    queryFn: () => wrapQueryPromise($get(Apis.LIST__MONITORING), MonitoringResponseData),
    staleTime: Infinity,
  });

  const control = useQuery({
    queryKey: [Apis.LIST__CONTROL],
    queryFn: () => wrapQueryPromise($get(Apis.LIST__CONTROL), ControlResponseData),
    staleTime: Infinity,
  });

  const meta = useQuery({
    queryKey: [Apis.META],
    queryFn: () => wrapQueryPromise($get(Apis.META), MetaResponseData),
    staleTime: Infinity,
  });

  return reactive({
    substation, dl, monitoring, control, meta
  })
}