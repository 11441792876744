<script setup lang="ts">
import { useRouter } from 'vue-router';
// import { HomeOutlined } from '@ant-design/icons-vue';
import { HOME_URL } from '@/constants/setting';

const router = useRouter();

const onClickHomeBtn = () => {
  router.push({
    path: HOME_URL,
    replace: true,
  });
}
</script>

<template>
  <a-space direction="vertical">
    <a-result 
      status="error" 
      title="404"
      sub-title="요청하신 페이지를 찾을 수 없습니다"
    >
      <template #extra>
        <a-button type="primary" class="text-center ma-2" @click="onClickHomeBtn">
          <!-- <HomeOutlined /> -->
          Back Home
        </a-button>
      </template>
    </a-result>
  </a-space>
</template>

<style scoped lang="scss"></style>