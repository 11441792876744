import { Routes } from './routes';

export const DATEPICKER_FORMAT = 'YYYY-MM-DD';

export const MODE = import.meta.env.MODE;
export const API_URL = import.meta.env.VITE_API_URL;
export const SETTLEMENT_API_URL = import.meta.env.VITE_SETTLEMENT_API_URL;
export const COMMON_API_URL = import.meta.env.VITE_COMMON_API_URL;
export const WS_URL = import.meta.env.VITE_WS_URL;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

export const HOME_URL = Routes.RESOURCE;

export const ERROR_NOTIFICATION_DURATION = 5; // 10
