import { z } from "zod";

export const JWTPayload = z.object({
  token_type: z.union([z.literal('access'), z.literal('refresh')]),
  exp: z.number(),
  iat: z.number(),
  jti: z.string(), // token id
  name: z.string(),
  email: z.string(),
  team: z.array(z.string()), // 운영팀
});