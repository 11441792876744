import { Keys } from "@/constants/key";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useMetaStore } from "./useMetaStore";

export const useCurrentTimeStore = defineStore(Keys.STORE.CURRENT_TIME, () => {
  const currentTime = ref(Math.floor(Date.now() / 1000) * 1000);

  const {
    getDayaheadBiddingTime,
    getDayaheadBiddingDeadlineTime,
  } = useMetaStore();

  const dayaheadBiddingTime = ref(getDayaheadBiddingTime());
  const dayaheadBiddingDeadlineTime = ref(getDayaheadBiddingDeadlineTime());
  const dayaheadBiddingCountdown = computed(() => dayaheadBiddingTime.value - currentTime.value);

  setInterval(() => {
    const now = Math.floor(Date.now() / 1000) * 1000;
    if (currentTime.value !== now) {
      currentTime.value = now;
    }
  }, 500);

  return {
    currentTime,
    dayaheadBiddingTime,
    dayaheadBiddingDeadlineTime,
    dayaheadBiddingCountdown,
  }
});