import * as Sentry from '@sentry/vue';
import { SENTRY_DSN } from '@/constants/setting';
// import { MODE } from '@/constants/setting';

export default {
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  sampleRate: 0.3,
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5,
};
