import { cloneDeep } from 'lodash';
import moment from 'moment';

import { Text } from '@/constants/const';

import { 
  BidDayaheadResponseData, 
  BidRealtimeResponseData,
} from '@/schema/api/bid.schema';
import { SchemaType, WithIdx } from '@/utils/types';


import { GetSection } from '@/interfaces/time';
import { GetSubmitState } from '@/interfaces/bid';

import { Grid } from '@/components/GridEditor.vue';

export const getUpdownTextItem = (
  value: unknown,
  options?: {
    reverseColorCls?: boolean;
    enabledZeroColorCls?: boolean;
    showZeroUnit?: boolean;
  }
) => {
  const num = Number(value);

  if (isNaN(num))
    return {
      colorCls: '',
      unit: '',
      absValue: '',
      value: '',
    };

  const absValue = Math.abs(num);
  if (num > 0) {
    return {
      colorCls: options?.reverseColorCls ? 'text-primary' : 'text-error',
      unit: '+',
      absValue,
      value,
    };
  } else if (num < 0) {
    return {
      colorCls: options?.reverseColorCls ? 'text-error' : 'text-primary',
      unit: '-',
      absValue,
      value,
    };
  } else {
    return {
      colorCls: options?.enabledZeroColorCls ? 'text-primary' : 'text-description',
      unit: options?.showZeroUnit ? '+' : '',
      absValue,
      value,
    };
  }
};

// [2024-04-25] 이택수
// 입찰서 데이터를 전달받아 공급가능용량을 반환한다.
// 현재는 1구간만 사용하므로 무조건 1구간을 공급가능용량으로 본다.
// 후에 backend에서 값 받아와서 사용하는 것으로 협의 (with 한승님)
export const getRealtimeAvailableCapacity = <T extends (SchemaType<typeof BidRealtimeResponseData> | Grid)>(data?: T) => {
  type ExcludeKey<T> = T extends NonNullable<SchemaType<typeof BidRealtimeResponseData>> ? Omit<T, 'submit_history'> : T;
  type Return = WithIdx<ExcludeKey<NonNullable<T>>>;

  if (data == null) return null;

  const _data = cloneDeep(data);
  
  if (Array.isArray(_data)) { // isGrid
    // is grid
    return {
      data: _data[0],
      idx: 0,
    } as Return;
  } else {
    // object
    return {
      data: _data[Text.SERVER__SECTION_1_BIDDING] as (number | null)[],
      idx: `section_1_bidding`,
    } as Return;
  }
};


// [2024-04-25] 이택수
// 입찰서 데이터를 전달받아 공급가능용량을 반환한다.
// 현재는 1구간만 사용하므로 무조건 1구간을 공급가능용량으로 본다.
// 후에 backend에서 값 받아와서 사용하는 것으로 협의 (with 한승님)
export const getDayAheadAvailableCapacity = <T extends SchemaType<typeof BidDayaheadResponseData> | Grid>(data?: T) => {
  if (!data) return null;

  const _data = cloneDeep(data);
  if (Array.isArray(_data)) {
    // is grid
    return {
      data: _data[0],
      idx: 0,
    } as WithIdx<T>;
  } else {
    return {
      data: _data[Text.SERVER__SECTION_1_BIDDING],
      idx: `section_1_bidding`,
    } as WithIdx<T>;
  }
};


/**
   * 집합자원별로 제출을 하므로 보유자원의 제출 상태는 소속된 집합자원의 상태와 같아진다.
   * 
   * 2024-04-17 기준 develop backend code 일부
   * Group Bidding
    class BiddingCondition(models.IntegerChoices):
        invalid = 0  # 과거 데이터를 채우는 경우
        normal = 1  # 정상 제출인 경우
        manual = 2  # 수동제출
        kpx = 3  # kpx에 제출된 값 - 제출실패 시 kpx에서 값을 가져와야 한다.
    class SubmitState(models.IntegerChoices):
        """
        이 State의 역할은 입찰서를 생성할 때 실제로 제출이 이루어진 값인지 아닌지를 판단하는 역할을 한다.
        """
        ready = 1  # 제출전
        done = 2  # 제출성공
        fail = 3  # 제출실패
        cancle = 4  # 제출취소
        deprecated = 5  # 입찰시간 이후 ready 상태 입찰값은 필요없음
   */
export const getSubmitState: GetSubmitState = (submitState, biddingCondition) => {
  if (submitState === 1) {
    return 'pre_bid';
  } else if (submitState === 2 && biddingCondition === 1) {
    return 'auto_bid_success';
  } else if (submitState === 2 && biddingCondition === 2) {
    return 'manual_bid_success';
  } else if (submitState === 2 && biddingCondition === 3) {
    return 'kpx_bid_success';
  } else if (submitState === 3) {
    return 'bid_failed';
  } else if (submitState === null && biddingCondition === null) {
    return 'create_failed';
  } else {
    return 'impossible';
  }
}

/** 
 * @return [시간, 15분 구간]
 * @example 12:00 -> [12, 1], 12:26 -> [12, 2]
*/
export const getSectionItem: (value?: number) => [number, number] = (value) => {
  if (!value) {
    value = Date.now();
  }
  const cur = moment(value);
  return [
    cur.get('hours') + 1,
    Math.floor(cur.get('minutes') / 15) + 1,
  ];
}

// 시간(moment)를 전달받아 해당 시간이 속한 section 정보를 반환한다. 
export const getSection: GetSection = (time) => {
  const TERM = 15;
  const hour = time.get('hours')+1; // 1~24
  const section = Math.floor(time.get('minutes') / TERM) as 0 | 1 | 2 | 3;

  const start = time.clone().set('minutes', section*TERM).startOf('minutes');
  const end = start.clone().add(TERM-1, 'minutes').endOf('minutes');

  return {
    hourSection: [hour, section],
    start, 
    end,
    base: time,
    next: () => getSection(time.clone().add(TERM, 'minutes')),
    prev: () => getSection(time.clone().add(TERM * -1, 'minutes')),
  };
}