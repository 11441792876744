import moment from "moment";
import { onBeforeUnmount, onMounted, ref } from "vue";

export const useInterval = (
  callback: Function,
  seconds: number = 1,
  start?: moment.Moment,
) => {
  const _startTimeoutId = ref<any>(-1);
  const _intervalId = ref<number>(-1);

  const run = () => {
    callback();

    _intervalId.value = setInterval(() => {
      callback();
    }, seconds * 1000) as any;
  }

  onMounted(() => {
    if (start) {
      _startTimeoutId.value = setTimeout(() => {
        clearTimeout(_startTimeoutId.value);
        run();
      }, start.diff(moment()));
    } else {
      run();
    }
  });

  onBeforeUnmount(() => {
    clearTimeout(_startTimeoutId.value);
    clearInterval(_intervalId.value);
  });
}