import { Text } from '@/constants/const';
import { z } from 'zod';

const BiddingRecordKey = z.union([z.string(), z.number()]);
export const BiddingHoulrlyArray = z.array(z.number().nullable()).length(24);

export const BiddingCondition = z.union([z.literal(0), z.literal(1), z.literal(2), z.literal(3)]).nullable();
export const BiddingSubmitState = z.union([z.literal(1), z.literal(2), z.literal(3)]).nullable();

const BiddingSubmitStateList = z.array(BiddingSubmitState).length(24);
const BiddingConditionList = z.array(BiddingCondition).length(24);
const BiddingSectionArray = z.array(z.number().nullable()).length(4); // 시간에 4구간을 나타냄
const BiddingHoulrlySectionArrayRecord = z.record(BiddingRecordKey, BiddingSectionArray); // 0 ~ 23

export const BiddingSubmitHistory = z.object({
  submit_at: z.string(), // HH:mm
  submit_state: BiddingSubmitState, //
  bidding_condition: BiddingCondition, //
  bidding: z.number().nullable(), //
  price: z.number().nullable(), //
});

const Bidding = z.object({
  [Text.SERVER__SECTION_1_BIDDING]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_1_PRICE]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_2_BIDDING]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_2_PRICE]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_3_BIDDING]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_3_PRICE]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_4_BIDDING]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_4_PRICE]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_5_BIDDING]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_5_PRICE]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_6_BIDDING]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_6_PRICE]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_7_BIDDING]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_7_PRICE]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_8_BIDDING]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_8_PRICE]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_9_BIDDING]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_9_PRICE]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_10_BIDDING]: BiddingHoulrlyArray,
  [Text.SERVER__SECTION_10_PRICE]: BiddingHoulrlyArray,
});

export const DayaheadAvailableCapacityMap = z
  .record(
    BiddingRecordKey,
    z.object({
      bidding: BiddingHoulrlyArray,
      da_se: BiddingHoulrlyArray, // 하루전 발전계획
    })
  )
  .nullable();

export const RealtimeAvailableCapacityMap = z
  .record(
    BiddingRecordKey,
    z.object({
      rt_se: BiddingHoulrlySectionArrayRecord, // 실시간 발전계획
      rt_smp: BiddingHoulrlySectionArrayRecord,
      bidding: BiddingHoulrlyArray,
      winning: BiddingHoulrlyArray,
    })
  )
  .nullable();

export const GroupBiddingRequestData = z.object({
  bidding: z.object({
    group: Bidding,
    resource: z.record(BiddingRecordKey, BiddingHoulrlyArray),
  }),
  bid_at: z.string(),
  is_algorithm: z.boolean(),
});

export const BidDayaheadResponseData = Bidding.merge(
  z.object({
    da_se: BiddingHoulrlyArray, // 하루전 발전계획
    bidding_condition: BiddingConditionList,
    submit_state: BiddingSubmitStateList,
    [Text.SERVER__MINIMUM_BIDDING]: BiddingHoulrlyArray,
  })
).nullable();

export const BidRealtimeResponseData = Bidding.merge(
  z.object({
    da_se: BiddingHoulrlyArray, // 하루전 발전계획
    rt_se: BiddingHoulrlySectionArrayRecord,
    rt_smp: BiddingHoulrlySectionArrayRecord,
    bidding_condition: BiddingConditionList,
    submit_state: BiddingSubmitStateList,
    submit_history: z.record(z.string(), z.array(BiddingSubmitHistory)),
    [Text.SERVER__MINIMUM_BIDDING]: BiddingHoulrlyArray,
  })
).nullable();

export const BidInfoResponseData = z
  .object({
    group: z.object({
      update_at: z.array(z.string().nullable()).length(24),
      capacity: z.array(z.number().nullable()).length(24),
    }),
    resource: z.record(
      z.string(),
      z.object({
        update_at: z.array(z.string().nullable()).length(24),
        capacity: z.array(z.number().nullable()).length(24),
      })
    ),
  })
  .nullable();

export const CalcedBidResponseData = z
  .object({
    max_bidding: z.array(z.number().nullable()).length(24),
  })
  .nullable();

// [TAMNA-808]
export const SubmitStateResponseData = z
  .object({
    submit: z.object({
      submit_at: z.string().nullable(),
      bidding_condition: BiddingCondition,
      submit_state: BiddingSubmitState,
    }),
  })
  .nullable();

export const BidingLowerLimitPrice = z.object({
  lower_limit_price: z.number(), // -198.31
});

export const DayaheadSmpResponseData = z.array(
  z.object({
    date: z.string(),
    area_type: z.union([z.literal('land'), z.literal('jeju')]),
    prices: z.array(z.number().nullable()).nullish(), // 원/kWh
  })
);

export const RealtimeSmpResponseData = z.array(
  z.object({
    date: z.string(),
    area_type: z.union([z.literal('land'), z.literal('jeju')]),
    confirmation_yn: z.string().nullish(),
    market_product_type: z.string().nullish(),
    prices: z.array(z.array(z.number().nullable()).length(4)).nullish(), // 원/kWh
  })
);
