<script setup lang="ts">
import { useNetworkCheck } from '@/hooks/useNetworkCheck';

useNetworkCheck('online');
</script>

<template>
  <a-space direction="vertical">
    <a-result 
      status="warning" 
      title="Network Offline"
      sub-title="네트워크 상태를 확인해 주세요. 네트워크 재연결 시 이전 화면으로 자동 전환됩니다."
    ></a-result>
  </a-space>
</template>