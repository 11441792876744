export const Keys = Object.freeze({
  NOTIFY: {
    CONTRACT: 'notify--contract',
  },
  STORE: {
    COMMON: 'store--common',
    CURRENT_TIME: 'store--current_time',
    REQUEST: 'store--request',
    USER: 'store--user',
    META: 'store--meta',
    SETTLEMENT_META: 'store--settlement_meta',
    ALERT: 'store--alert',
  },
});
