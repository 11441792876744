import { z } from "zod";

// 급전지시
export const DispatchOrder = z.object({
  // https://idnstory.atlassian.net/browse/TAMNA-592?focusedCommentId=36782
  bid_at: z.string().nullable(), // "2024-05-08"
  hour: z.number().nullable(), // 14 [0~23?] [1~24?]
  section: z.number().nullable(), // 0 [0~3]
  target_power: z.number(), // 123.1345
  request_at: z.string().nullable(), // "2024-05-08T13:00:00"
}).nullable();

export const SelfControlOrder = z.object({
  // https://idnstory.atlassian.net/browse/TAMNA-726
  bid_at: z.string().nullable(), // "2024-05-08"
  hour: z.number().nullable(), // 14 [0~23?] [1~24?]
  section: z.number().nullable(), // 0 [0~3]
});

export const AutoControlState = z.object({
  is_auto: z.boolean(), // true
});

// https://idnstory.atlassian.net/browse/TAMNA-758
export const GroupControlState = z.object({
  real: z.array(z.object({ // 집합자원 중 현재 실제로 제어중인 자원에 대한 데이터를 받는다.
    r_id: z.number(),
    // README: 원칙적으로는 null값이 들어와서는 안되지만 특정제어방식 기획이 부족해 구현되지 않은 자원에 대해서는 null값이 들어올 수 있다.
    target_power: z.number().nullable(),
    is_auto: z.boolean(),
  })), // 기존 is_real_control true인 경우는 real_control로 본다.
  // display용
  mock: z.array(z.object({ // 집합자원에 포함되는 모든 자원
    r_id: z.number(),
    // real과 같은값으로 예상한다. 
    // 화면에 출력할때는 real에 target_power를 우선해서 보여준다.
    target_power: z.number().nullable(), 
    input_meter: z.array(z.number()).min(1),
    time: z.string(),
  })),
  wait: z.array(z.object({
    r_id: z.number(), 
    target_power: z.number().nullable(),
  })),
});

export const GroupControlOrder = z.object({
  status_confirmed: z.boolean(),
  status: z.union([z.literal('dispatch_order'), z.literal('self_control')]).nullable(),
  target_power: z.number().nullable(),
});