import z from "zod";

export const TokenRequestData = z.object({
  email: z.string(),
  password: z.string(),
});

export const TokenResponseData = z.object({
  access: z.string(),
  refresh: z.string(),
});

export const RefreshRequestData = z.object({
  refresh: z.string(),
});

export const RefreshResponseData = z.object({
  access: z.string(),
});
