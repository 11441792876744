<script setup lang="ts">
import CommonThemeProvider from '@/components/provider/CommonThemeProvider.vue';
import { useQueryClient } from '@tanstack/vue-query';
import { onMounted } from 'vue';

import { useUserInfo } from '@/hooks/useUserInfo';

const queryClient = useQueryClient();
const { userInfo } = useUserInfo();
userInfo;

onMounted(() => {
  queryClient.setDefaultOptions({
    queries: {
      retry: 0,
    },
  });
});
</script>

<template>
  <div class="d-flex flex-1">
    <CommonThemeProvider>
      <router-view></router-view>
    </CommonThemeProvider>
    <!-- custom scrollbar -->
    <div id="customScrollContainer"></div>
  </div>
</template>

<style lang="scss" scoped></style>
